import { CommonModule } from '@angular/common';
import { HTTP_INTERCEPTORS, HttpClientModule } from '@angular/common/http';
import { NgModule } from '@angular/core';
import { FlexLayoutModule } from '@angular/flex-layout';
import { ReactiveFormsModule } from '@angular/forms';
import { MatCardModule } from '@angular/material/card';
import { MatCheckboxModule } from '@angular/material/checkbox';
import { MatExpansionModule } from '@angular/material/expansion';
import { MatFormFieldModule } from '@angular/material/form-field';
import { MatGridListModule } from '@angular/material/grid-list';
import { MatTableModule } from '@angular/material/table';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { RouterModule } from '@angular/router';
import { FontAwesomeModule } from '@fortawesome/angular-fontawesome';
import { library } from '@fortawesome/fontawesome-svg-core';
import { faChevronRight, faExclamationTriangle, faFilter, faTimes } from '@fortawesome/free-solid-svg-icons';
import { MatSelectInfiniteScrollModule } from 'ng-mat-select-infinite-scroll';
import { NgxSpinnerModule, NgxSpinnerService } from 'ngx-spinner';
import { BackToHomeModalComponent } from '../order/components/modals/back-to-home-modal/back-to-home-modal.component';
import { OrderRoutingModule } from '../order/routing/order.routing';
import { LoadingService } from '../order/services/internal/loading/loading.service';
import { ScrollService } from '../order/services/internal/scroll/scroll.service';
import { JwtInterceptor } from '../shared/helper/jwt.interceptor';
import { SharedComponentsModule } from '../shared/shared-components.module';
import { LoginComponent } from './components/login/login.component';
import { ProposalFilterModalComponent } from './components/modals/proposal-filter-modal/proposal-filter-modal.component';
import { PasswordRecoveryComponent } from './components/password-recovery/password-recovery.component';
import { ProposalDetailComponent } from './components/proposal-detail/proposal-detail.component';
import { BankingDataDetailComponent } from './components/proposal-detail/templates/banking-data-detail/banking-data-detail.component';
import { BusinessDataDetailComponent } from './components/proposal-detail/templates/business-data-detail/business-data-detail.component';
import { CommercialConditionsDataDetailComponent } from './components/proposal-detail/templates/commercial-conditions-data-detail/commercial-conditions-data-detail.component';
import { ContractedServicesDataDetailComponent } from './components/proposal-detail/templates/contracted-services-data-detail/contracted-services-data-detail.component';
import { RateDataTableComponent } from './components/proposal-detail/templates/contracted-services-data-detail/rate-data-table/rate-data-table.component';
import { CreditRiskNotesDetailComponent } from './components/proposal-detail/templates/credit-risk-notes-detail/credit-risk-notes-detail.component';
import { DeliveryInformationDataDetailComponent } from './components/proposal-detail/templates/delivery-information-data-detail/delivery-information-data-detail.component';
import { DeliveryInstallationDetailsComponent } from './components/proposal-detail/templates/delivery-installation-details/delivery-installation-details.component';
import { DeliveryStepperComponent } from './components/proposal-detail/templates/delivery-installation-details/delivery-stepper/delivery-stepper.component';
import { OfferDataDetailComponent } from './components/proposal-detail/templates/offer-data-detail/offer-data-detail.component';
import { OwnerDataDetailComponent } from './components/proposal-detail/templates/owner-data-detail/owner-data-detail.component';
import { ProposalDataDetailComponent } from './components/proposal-detail/templates/proposal-data-detail/proposal-data-detail.component';
import { ProposalNoteComponent } from './components/proposal-detail/templates/proposal-note/proposal-note.component';
import { RegistrationDataDetailComponent } from './components/proposal-detail/templates/registration-data-detail/registration-data-detail.component';
import { ProposalHistoryComponent } from './components/proposal-history/proposal-history.component';
import { WelcomeComponent } from './components/welcome/welcome.component';
import { StartGuard } from './guards/start-guard';
import { StartRoutingModule } from './routing/start.routing';
import { StartComponent } from './start.component';
import { CampaignSimulatorComponent } from './components/campaign-simulator/campaign-simulator.component';
import { TechnologyPriceTableComponent } from './components/campaign-simulator/technology-price-table/technology-price-table.component';
import { PrepaymentTableComponent } from './components/campaign-simulator/prepayment-table/prepayment-table.component';
import { FactorTableComponent } from './components/campaign-simulator/factor-table/factor-table.component';
import { IdentifyDataDetailComponent } from './components/proposal-detail/templates/identify-data-detail/identify-data-detail.component';

@NgModule({
  declarations: [
    WelcomeComponent,
    LoginComponent,
    PasswordRecoveryComponent,
    StartComponent,
    ProposalHistoryComponent,
    ProposalFilterModalComponent,
    ProposalDetailComponent,
    RegistrationDataDetailComponent,
    OfferDataDetailComponent,
    ProposalDataDetailComponent,
    DeliveryInformationDataDetailComponent,
    ProposalNoteComponent,
    BusinessDataDetailComponent,
    OwnerDataDetailComponent,
    BankingDataDetailComponent,
    CommercialConditionsDataDetailComponent,
    ContractedServicesDataDetailComponent,
    CreditRiskNotesDetailComponent,
    DeliveryInstallationDetailsComponent,
    RateDataTableComponent,
    TechnologyPriceTableComponent,
    PrepaymentTableComponent,
    FactorTableComponent,
    DeliveryStepperComponent,
    CampaignSimulatorComponent,
    IdentifyDataDetailComponent,
  ],
  imports: [
    CommonModule,
    StartRoutingModule,
    RouterModule,
    HttpClientModule,
    MatFormFieldModule,
    MatExpansionModule,
    ReactiveFormsModule,
    BrowserAnimationsModule,
    MatCheckboxModule,
    MatGridListModule,
    FlexLayoutModule,
    SharedComponentsModule,
    FontAwesomeModule,
    MatSelectInfiniteScrollModule,
    FontAwesomeModule,
    MatCardModule,
    MatTableModule,
  ],
  exports: [WelcomeComponent, LoginComponent],
  providers: [StartGuard, { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true }],
})
export class StartModule {
  constructor() {
    library.add(faChevronRight, faFilter, faTimes, faExclamationTriangle);
  }
}
