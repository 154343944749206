import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit, ViewChild } from '@angular/core';
import { AbstractControl, FormArray, FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatCheckboxChange } from '@angular/material/checkbox';
import { MatDialog } from '@angular/material/dialog';
import { SafeHtml } from '@angular/platform-browser';
import { icon } from '@fortawesome/fontawesome-svg-core';
import { takeWhile, tap } from 'rxjs/operators';
import { DocumentCategory } from 'src/app/order/enums/document-category.enum';
import { ModalDefinitions } from 'src/app/order/enums/modal-definitions.enum';
import { OfferTypeEnum } from 'src/app/order/enums/offer-type.enum';
import { PersonTypeEnum } from 'src/app/order/enums/person-type.enum';
import { TechnologyGroupEnum } from 'src/app/order/enums/technology-group.enum';
import { TechnologySolutionEnum } from 'src/app/order/enums/technology-solution.enum';
import { Messages } from 'src/app/order/messages/order.messages';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';
import { ClientService } from 'src/app/order/services/external/client-validation/client-validation.service';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { TechnologyService } from 'src/app/order/services/external/technology/technology.service';
import { TerminalService } from 'src/app/order/services/external/terminal/terminal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { DataStore } from 'src/app/order/store/data.store';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { WebsiteValidator } from 'src/app/order/validators/website.validator';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { PaymentSplit, ProductModel, ProductSelectionModel, Proposal } from 'src/app/shared/models/proposal';
import { TechnologyModel } from 'src/app/shared/models/response/response-technology-model';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { ConfigPaymentSplit } from '../../../../shared/models/config-payment-split';
import { cpfCnpjMask } from '../../../masks/document-masks';
import { CpfCnpjValidator } from '../../../validators/cpf-cnpj-validator';
import { ECommerceFunctionalitiesModel } from '../../complementary-information/e-commerce/models/e-commerce-funcionalities.model';
import { FormStep } from '../../form-step';
import { ValueEditorDialogData, ValueEditorModalComponent } from '../../modals/value-editor-modal/value-editor-modal.component';
import { SplitTech } from './models/terminal-amount.model';
import { MatTooltip } from '@angular/material/tooltip';

@Component({
  selector: 'app-product-selection',
  templateUrl: './product-selection.component.html',
  styleUrls: ['./product-selection.component.scss'],
})
export class ProductSelectionComponent extends FormStep implements OnInit {
  @ViewChild('tooltip') public tooltip: MatTooltip;

  get isSales(): boolean {
    return this.offerType === OfferTypeEnum.SALES;
  }

  get hasECommerceExceptionCnaeSelected() {
    return this.proposal && this.proposal.offerDataDetails && this.proposal.offerDataDetails.eCommerceExceptionCnaeSelected;
  }

  get addTechnologyButtonHTML() {
    return this.renderPlusIcon() + ' <span>adicionar tecnologia</span>';
  }

  get removeTechnologyButtonHTML() {
    return this.renderCloseIcon() + ' <span>remover tecnologia</span>';
  }

  get selectedProducts(): ProductModel[] {
    const productSelection: ProductModel[] = [];
    for (let index = 0; index < this.technologyFormsArray.length; index++) {
      const model: ProductModel = (this.technologyFormsArray.controls[index] as FormGroup).getRawValue();
      if (model.technology && model.price && model.amount) {
        productSelection.push(model);
      }
    }
    return productSelection;
  }

  get MAXIMUM_PRODUCT_AMOUNT() {
    const amount = this.getAmount();
    if (amount && amount.length) {
      return amount.length;
    }
  }

  private get technologyFormsArray(): FormArray {
    return this.formControls.technologyForms as FormArray;
  }

  public get technologyFormsControls() {
    return this.technologyFormsArray.controls;
  }

  public ediItem: Item = {
    value: 'EDI',
    label: 'Extrato Eletrônico (EDI)',
  };

  public paymentSplitItem: Item = {
    value: 'paymentSplitMaster',
    label: 'Split de Pagamentos (EC Master)',
  };

  public paymentLinkItem: Item = {
    value: 'PAYMENT_LINK',
    label: 'Link de pagamento',
  };

  public hasTef: boolean;
  public hasECommerce: boolean;
  public hasPOS: boolean;
  public cloverTechSelected: boolean = false;

  public hasConexaoIp: boolean;
  public hasPosSmart: boolean;
  public hasTefBrowser: boolean;
  public hasTefBrowserOnly: boolean;

  public STEP_NUMBER = 4;
  public proposal: Proposal;
  public offerType: OfferTypeEnum;
  public configuration: ConfigurationModel;
  public technologyList: TechnologyModel[];
  public technologyListUpdatePrice: TechnologyModel[];
  public allTechnologies: TechnologyModel[];
  public excludedTechnologyGroup: string;
  public alreadySelectedTechnologies: string[];

  public maxValue: number;
  public minValue: number;
  public idCampaign: string;
  public getValueRange = false;

  private readonly CNPJ_REGEX = /^[0-9]{14}$/;
  private readonly CPF_REGEX = /^[0-9]{11}$/;

  private splitTechs: SplitTech[] = null;
  private ableSplitPos: boolean = false;
  private ableSplitTef: boolean = false;

  private readonly ECOMMERCE_TECHNOLOGY_GROUP = 'COM';

  private INSTITUTION_FISERV = '00000007';
  private SERVICE_CONTRACT_BIN = 125;
  private ID_CHANNEL_TYPE_ISO_NOVO = '092';

  constructor(
    private formBuilder: FormBuilder,
    private technologyService: TechnologyService,
    private terminalService: TerminalService,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private clientService: ClientService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public checkIfHasTef() {
    const allTechnologies = [];
    this.technologyFormsArray.controls.forEach((form) => {
      const parsed = form as FormGroup;

      if (parsed && parsed.controls && parsed.controls.technology && parsed.controls.technology.value) {
        allTechnologies.push(parsed.controls.technology.value);
      }
    });

    const hasTef =
      this.technologyList.filter(
        (x) =>
          x.idService !== '353' &&
          x.idService !== '354' &&
          x.technologyGroup === TechnologyGroupEnum.TEF &&
          allTechnologies.indexOf(x.idService) > -1
      ).length > 0;

    this.hasTef = hasTef;
  }

  public setPrice(productIndex: number) {
    const relatedFormGroup = this.technologyFormsArray.controls[productIndex] as FormGroup;
    const rawValue = relatedFormGroup.getRawValue();

    if (relatedFormGroup) {
      this.loadTechnologiesList(this.technologyList, productIndex);

      this.hasCloverTech();

      this.checkIfHasTef();

      const suggestedTechPrice = this.technologyList
        .filter((x) => x.idService === rawValue.technology)
        .map((prop) => prop.suggestedTechPrice)[0];

      this.checkEcommerceAmount(true, productIndex);
      this.checkPOSAmount();

      relatedFormGroup.controls.price.setValue(this.formatPrice(suggestedTechPrice));
    }

    if (this.hasECommerce) {
      this.paymentSplitItem.selected = false;
    }

    if (this.hasSplitTech()) {
      this.technologyFormsArray.controls.forEach((form) => {
        if (form instanceof FormGroup) {
          form.controls.amount.setValue(1);
          form.controls.amount.disable();
        }
      });
      this.formControls.ecMasterDocument.setValidators([Validators.required, CpfCnpjValidator]);
      this.dialogService.openDialog(Messages.WARN_ABOUT_SPLIT_TECHNOLOGY);
    } else {
      this.formControls.ecMasterDocument.clearValidators();
      this.formControls.ecMasterDocument.updateValueAndValidity();
    }

    this.checkAbleSplit();
  }

  public formatPrice(price: string): string {
    return 'R$ ' + parseFloat(price).toFixed(2);
  }

  public getAlltechnology(product: ProductModel, index: number) {
    if (this.technologyListUpdatePrice) {
      for (const prodfind of this.technologyListUpdatePrice) {
        if (product.technologyName === prodfind.description) {
          product.price = Number(prodfind.suggestedTechPrice);
          break;
        }
      }

      this.addTechnologyForm(product);
      const relatedFormGroup = this.technologyFormsArray.controls[index] as FormGroup;

      relatedFormGroup.controls.price.setValue(this.formatPrice(product.price.toString()));
    } else {
      this.addTechnologyForm(product);
    }
  }

  public setMaxValue(value: number) {
    this.maxValue = value;
  }

  public setMinValue(value: number) {
    this.minValue = value;
  }

  public getValuesRange(event: any) {}

  public openValueEditorModal(dialogData: ValueEditorDialogData, productIndex: number) {
    const relatedFormGroup = this.technologyFormsArray.controls[productIndex] as FormGroup;

    if (relatedFormGroup) {
      const rawValue = relatedFormGroup.getRawValue();
      if (rawValue && rawValue.technology) {
        const selectedTechnologyName = this.technologyList
          .filter((x) => x.idService === rawValue.technology)
          .map((prop) => prop.description)[0];
        const selectedTechnologyId = this.technologyList
          .filter((x) => x.idService === rawValue.technology)
          .map((prop) => prop.idService)[0];

        const valuePrice = rawValue.price;

        let personType = PersonTypeEnum.LEGAL;
        if (this.isPf()) {
          personType = PersonTypeEnum.PHYSICAL;
        }

        this.technologyService
          .rangeByTechnology(personType, Number(this.proposal.offerDataDetails.revenueRange), selectedTechnologyId, this.idCampaign)
          .pipe(takeWhile(() => this.alive))
          .subscribe(
            (range) => {
              if (range === null) {
                this.dialogService.openDialog(Messages.FAILED_TO_GET_RANGE, () => this.back());
              }

              this.setMaxValue(range[0].suggestedTechPrice);

              this.setMinValue(range[0].minTechPrice);

              dialogData.itemDescription = selectedTechnologyName;
              dialogData.maxValue = this.maxValue;
              dialogData.minValue = this.minValue;
              dialogData.confirmCallbackFn = this.onModalConfirm.bind(this);
              dialogData.cancelCallbackFn = this.onModalClose;

              this.dialog.open(ValueEditorModalComponent, {
                disableClose: true,
                width: ModalDefinitions.DEFAULT_MODAL_WIDTH,
                data: dialogData,
              });
            },
            (error) => {
              if (error.error && error.error.message) {
                this.dialogService.openDialogWithMessage(error.error.message, () => {
                  return;
                });
                return;
              }
              this.dialogService.openDialog(Messages.FAILED_TO_GET_RANGE, () => this.back());
            }
          );
        return;
      }
    }
    this.dialogService.openDialog(Messages.UNABLE_TO_OPEN_PRICE_EDIT_DIALOG);
    relatedFormGroup.controls.technology.markAsTouched();
  }

  public onModalConfirm(data: ValueEditorDialogData) {
    data.formGroup.controls.priceEdit.setErrors(null);
    data.formControl.setValue(this.formatPrice(data.formGroup.controls.priceEdit.value));
  }

  public onModalClose(data: any) {
    data.formGroup.controls.priceEdit.setErrors(null);
  }

  public async ngOnInit() {
    await super.ngOnInit();
    this.idCampaign = this.proposal.offerDataDetails.campaign;

    if (this.offerType === OfferTypeEnum.SALES) {
      if (this.technologyFormsArray && this.technologyFormsArray.controls) {
        const formGroupList: FormGroup[] | AbstractControl[] = this.technologyFormsArray.controls;

        formGroupList.forEach((formGroup) => {
          if (formGroup instanceof FormGroup) {
            const controls = formGroup.controls;
            if (controls && controls.amount && controls.price) {
              controls.amount.setValue(1);
            }
          }
        });
      }
    }

    this.checkSplitTechs();
  }

  public checkAbleSplit() {
    this.proposalService.ablePaymentSplit(this.proposal.serviceContract).subscribe(
      (ablePaymentSplit: ConfigPaymentSplit) => {
        this.ableSplitPos = ablePaymentSplit.pos;
        this.ableSplitTef = ablePaymentSplit.tef;
      },
      () => {
        this.ableSplitTef = false;
        this.ableSplitPos = false;
        this.dialogService.openDialog(Messages.CHECK_ABLE_PAYMENT_SPLIT);
      }
    );
  }

  public checkJumpDeliverySteps(hasECommerce: boolean) {
    this.setStepJump(
      orderRoutingDefinitions.openingHours.stepNumber,
      orderRoutingDefinitions.openingHours.routes.WORKING_HOURS.order,
      hasECommerce
    );
    this.setStepJump(
      orderRoutingDefinitions.openingHours.stepNumber,
      orderRoutingDefinitions.openingHours.routes.DELIVERY_HOURS.order,
      hasECommerce
    );
  }

  public onCheckboxChange(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
  }

  public onCheckboxChangePaymentSplit(item: Item, event: MatCheckboxChange) {
    item.selected = event.checked;
  }

  public addForm() {
    if (this.isFormGroupValid()) {
      this.addTechnologyForm();
      this.loadTechnologies(this.fieldsArray.length - 1);
    }
  }

  public checkEcommerceAmount(removeAmountFields: boolean = false, productIndex?: number) {
    const isECommerceArr = [];

    this.technologyFormsArray.controls.forEach((form, index, arr) => {
      if (form instanceof FormGroup) {
        const rawValue = form.getRawValue();
        const isEcommerce = this.technologyList.some(
          (x) => x.technologyGroup === TechnologyGroupEnum.E_COMMERCE && rawValue.technology === x.idService
        );

        isECommerceArr.push(isEcommerce);

        if (!isEcommerce) {
          this.formControls.url.setErrors(null);
        }

        if (isEcommerce) {
          form.controls.amount.setValue(1);
          form.controls.amount.disable();
        } else if (removeAmountFields && index === productIndex) {
          form.controls.amount.setValue('');
          form.controls.amount.enable();
        }
      }
    });

    this.hasECommerce = isECommerceArr.some((x) => !!x);
  }

  public checkPOSAmount() {
    const isPOSArr = [];
    this.technologyFormsArray.controls.forEach((form, index, arr) => {
      if (form instanceof FormGroup) {
        (this.fieldsArray[index].amount as FdSelectConfig).items = [];
        const rawValue = form.getRawValue();

        var arrQuantity = [];

        if (this.technologyList && this.technologyList.length) {
          const isPOS = this.technologyList.some(
            (x) => x.technologyGroup === TechnologyGroupEnum.POS && rawValue.technology === x.idService
          );

          const isPosSmart = this.technologyList.some(
            (x) => x.technologyGroup === TechnologyGroupEnum.POS && rawValue.technology === TechnologySolutionEnum.APT_POS_SMART
          );

          isPOSArr.push(isPOS);
          this.hasPOS = isPOSArr.some((x) => !!x);

          if (!this.isSales) {
            if (isPOS) {
              this.terminalService.getPOSAmount(this.serviceContract, this.proposal.registrationDataPersonalInfo.cpfCnpj).subscribe(
                (response) => {
                  if (!response) {
                    this.dialogService.openDialog(Messages.ERROR_WHEN_FILLING_POS_AMOUNT);
                  } else if (!response.numberOfTerminalsAvailable) {
                    this.dialogService.openDialog(Messages.NO_POS_AMOUNT);
                  } else {
                    for (let i = 1; i <= response.numberOfTerminalsAvailable; i++) {
                      arrQuantity.push({ value: i, label: `${i}` });
                    }
                  }
                },
                (err: HttpErrorResponse) => {
                  this.dialogService.openDialog(Messages.ERROR_WHEN_VALIDATING_POS_AMOUNT);
                }
              );
            } else {
              arrQuantity = this.getAmount();
            }
          } else {
            arrQuantity = this.getAmount();
          }

          (this.fieldsArray[index].amount as FdSelectConfig).items = arrQuantity;
        }
      }
    });
  }

  public validateAmount(index: number) {
    const currentAmount = this.getTotalAmountOfProducts();
    const configuredAmount = this.getAmount();
    configuredAmount.reverse().splice(0, currentAmount);
    (this.fieldsArray[index].amount as FdSelectConfig).items = configuredAmount.reverse();
  }

  public getAmount(): Item[] {
    const documentCategory = this.proposal.registrationDataPersonalInfo.documentCategory;

    if (this.offerType === OfferTypeEnum.SALES) {
      return [{ value: 1, label: '1', selected: true }];
    } else if (documentCategory === DocumentCategory.CPF) {
      const arrQuantity: Item[] = [];

      if (!this.configuration || !this.configuration.numberTerminalsPf) {
        return;
      }

      for (let i = 1; i <= this.configuration.numberTerminalsPf; i++) {
        arrQuantity.push({
          value: i,
          label: `${i}`,
        });
      }
      return arrQuantity;
    } else {
      const arrQuantity: Item[] = [];

      if (!this.configuration || !this.configuration.numberTerminalsPj) {
        return;
      }

      for (let i = 1; i <= this.configuration.numberTerminalsPj; i++) {
        arrQuantity.push({
          value: i,
          label: `${i}`,
        });
      }
      return arrQuantity;
    }
  }

  public clickBtnNext(): void {
    if (this.isFormGroupValid()) {
      if (!this.splitTechs) {
        this.dialogService.openErrorDialog(
          Messages.ERROR_GET_CHECK_SPLITS_TECH,
          'Falha ao carregar tecnologias splits',
          () => this.back(),
          null,
          'error'
        );
        return;
      }

      if (this.checkIfHasRestrictTechnology(this.technologyList)) return;

      if (this.hasSplitTech() || this.paymentSplitItem.selected) {
        this.proposalService.ablePaymentSplit(this.proposal.serviceContract).subscribe(
          (ablePaymentSplit: ConfigPaymentSplit) => {
            if (ablePaymentSplit) {
              this.checkDuplicity();
            } else {
              this.dialogService.openDialog(Messages.CHECK_ABLE_PAYMENT_SPLIT);
            }
          },
          () => {
            this.dialogService.openDialog(Messages.CHECK_ABLE_PAYMENT_SPLIT);
          }
        );
      } else {
        this.checkDuplicity();
      }
    }
  }

  public ableSplitPayment() {}

  public hasSplitTech(): boolean {
    for (const control of this.technologyFormsArray.controls) {
      const formGroup = control as FormGroup;
      const code = formGroup.controls.technology.value as string;
      if (this.splitTechs) {
        for (const item of this.splitTechs) {
          if (item.cod === Number(code)) {
            this.ediItem.selected = false;
            return true;
          }
        }
      }
    }

    return false;
  }

  public showAddButtonOrEdi(): boolean {
    if (this.isSales) {
      return false;
    }
    return !this.hasSplitTech();
  }

  public isPJ(): boolean {
    return this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CNPJ;
  }

  public showSplitPaymentCheck(): boolean {
    return (
      !this.hasSplitTech() &&
      !this.hasECommerce &&
      this.isPJ() &&
      ((this.hasTef && this.ableSplitTef) || (this.hasPOS && this.ableSplitPos))
    );
  }

  public checkDuplicity() {
    const technologies = [];
    this.technologyFormsArray.controls.forEach((control) => {
      const formGroup = control as FormGroup;
      const rawValue = formGroup.getRawValue();
      const technologyGroup = this.technologyList.find((technology) => technology.idService === rawValue.technology).technologyGroup;
      technologies.push(technologyGroup);
    });

    this.proposalService
      .findDuplicates(this.proposal.registrationDataPersonalInfo.cpfCnpj, technologies)
      .toPromise()
      .then((resp) => {
        if (resp) {
          this.dialogService.openDialog(Messages.PROPOSAL_DUPLICITY_ERROR);
        } else {
          this.validateIfSipagAndPos();
        }
      })
      .catch((error) => {
        this.dialogService.openDialog(Messages.ERROR_WHEN_CHECK_PROPOSAL_DUPLICITY);
      })
      .finally(() => {});
  }

  public getFormControlByIndex(index: number) {
    const formArray = this.technologyFormsArray;
    return (formArray.controls[index] as FormGroup).controls;
  }

  public getTotalAmountOfProducts() {
    let amount = 0;
    if (this.technologyFormsArray && this.technologyFormsArray.controls) {
      const formGroupList: FormGroup[] | AbstractControl[] = this.technologyFormsArray.controls;

      formGroupList.forEach((formGroup) => {
        if (formGroup instanceof FormGroup) {
          const controls = formGroup.controls;
          if (controls && controls.amount) {
            amount += controls.amount.value;
          }
        }
      });
    }
    return amount;
  }

  public addTechnologyForm(product: Partial<ProductModel> = {}) {
    if (this.fieldsArray.length > 0 && !this.isFormGroupValid()) {
      return;
    }

    const totalAmount = this.getTotalAmountOfProducts();

    if (totalAmount >= this.MAXIMUM_PRODUCT_AMOUNT) {
      this.dialogService.openDialog(Messages.MAX_PRODUCT_LIMIT_REACHED);
      return;
    }

    this.fieldsArray.push(this.createFields());
    this.technologyFormsArray.push(this.createTechnologyForm(product));
  }

  public shouldShowRemoveButton(index: number): boolean {
    return this.fieldsArray.length > 1 && index < this.fieldsArray.length;
  }

  public removeTechnologyForm(index: number) {
    this.technologyFormsArray.removeAt(index);
    this.fieldsArray.splice(index, 1);
    this.checkEcommerceAmount();
  }

  public hasCloverTech(): boolean {
    const hasCloverTech = this.technologyFormsArray.getRawValue().some((tech) => tech.technology === '353' || tech.technology === '354');
    if (hasCloverTech)  { this.ediItem.selected = true }
    return hasCloverTech;
  }

  public isPf(): boolean {
    return this.proposal.registrationDataPersonalInfo.documentCategory === DocumentCategory.CPF;
  }

  public loadTechnologies(index: number) {
    if (this.technologyList && this.technologyList.length) {
      this.loadTechnologiesList(this.technologyList, index);
    } else {
      if (!this.proposal.offerDataType) {
        this.dialogService.openErrorDialog(
          Messages.FAILED_TO_GET_PROPOSAL_DATA,
          'Api Fiserv Online - Tecnologia',
          () => this.back(),
          null,
          'error'
        );
        return;
      }
      this.getServiceList(index, this.proposal.offerDataType.offerType, true);
    }
  }

  private checkIfHasRestrictTechnology(technologyList: TechnologyModel[]): boolean {
    this.hasPosSmart = false;
    this.hasTefBrowser = false;
    this.hasConexaoIp = false;
    var APOS_NAME: string;
    var CONEXAO_IP_NAME: string;
    var TEF_BROWSER_NAME: string;

    technologyList.forEach((technology) => {
      if (technology.idService == TechnologySolutionEnum.APT_POS_SMART) {
        APOS_NAME = this.tecnologyLabelName(technology);
      }

      if (technology.idService == TechnologySolutionEnum.TFI_CONEXAO_IP) {
        CONEXAO_IP_NAME = this.tecnologyLabelName(technology);
      }

      if (technology.idService == TechnologySolutionEnum.PTF_TEF_BROWSER) {
        TEF_BROWSER_NAME = this.tecnologyLabelName(technology);
      }
    });

    this.technologyFormsArray.controls.forEach((form) => {
      const parsed = form as FormGroup;
      if (parsed && parsed.controls && parsed.controls.technology && parsed.controls.technology.value) {
        if (parsed.controls.technology.value === TechnologySolutionEnum.APT_POS_SMART) this.hasPosSmart = true;

        if (parsed.controls.technology.value === TechnologySolutionEnum.TFI_CONEXAO_IP) this.hasConexaoIp = true;

        if (parsed.controls.technology.value === TechnologySolutionEnum.PTF_TEF_BROWSER) this.hasTefBrowser = true;
      }
    });

    return false;
  }

  private menssagemTecnologiasRestrita(CONEXAO_IP_NAME: string, TEF_BROWSER_NAME: string, APOS_NAME: string): Messages {
    return new Messages(
      'Atenção',
      'Não é possível prosseguir com a tecnologia '.concat(
        CONEXAO_IP_NAME,
        ' as tecnologias ',
        APOS_NAME,
        ' e ',
        TEF_BROWSER_NAME,
        ' já possuem ',
        CONEXAO_IP_NAME,
        ' vinculada por padrão.'
      )
    );
  }

  private menssagemTecnologiaRestrita(CONEXAO_IP_NAME: string, labelTecnologiaRestrita: string): Messages {
    return new Messages(
      'Atenção',
      'Não é possível prosseguir com a tecnologia '.concat(
        CONEXAO_IP_NAME,
        ' a tecnologia ',
        labelTecnologiaRestrita,
        ' já possui ',
        CONEXAO_IP_NAME,
        ' vinculada por padrão.'
      )
    );
  }

  private loadTechnologiesList(items: TechnologyModel[], index: number): Item[] {
    this.checkIfHasRestrictTechnology(items);

    var itemsToAdd = this.mapToItemArray(items);

    (this.fieldsArray[index].technology as FdSelectConfig).items = [];
    (this.fieldsArray[index].technology as FdSelectConfig).items.push(...itemsToAdd);

    return itemsToAdd;
  }

  public getSpecificPriceProperties() {
    return {
      modalTitle: 'Indique valor unitário de aluguel da tecnologia abaixo:',
      childControl: {
        controlName: 'priceEdit',
        label: 'Valor solicitado',
        messages: {
          required: 'Informe o valor',
          invalid: 'Valor inválido',
          valueTooLow: 'O valor deve ser maior que o mínimo especificado',
          valueTooHigh: 'O valor deve ser menor que o máximo especificado',
        },
      },
      messages: {
        required: 'Informe o preço',
        invalid: 'preço inválido',
      },
    };
  }

  public validateECMaster(document) {
    if ((this.CPF_REGEX.test(document) || this.CNPJ_REGEX.test(document)) && this.formControls.ecMasterDocument.valid) {
      this.proposalService.validateEcMaster(document, this.proposal.institution).subscribe(
        (validEcMaster) => {
          if (!validEcMaster) {
            this.formControls.ecMasterDocument.setValue('');
            this.dialogService.openDialog(Messages.EC_MASTER_NOT_FOUND);
          }
        },
        () => {
          this.formControls.ecMasterDocument.setValue('');
          this.dialogService.openDialog(Messages.GENERAL_ERROR);
        }
      );
    }
  }
  protected proposalGetCallback(): void {
    if (this.dataStore.isObjectEmpty(this.proposal) || !this.proposal.offerDataType || !this.proposal.offerDataType.offerType) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Tecnologia',
        () => this.back(),
        null,
        'error'
      );
    }
    this.configuration = this.configurationService.getConfigurationFromSessionStorage();
    this.offerType = this.proposal.offerDataType.offerType;
  }

  protected persistData() {
    const totalAmount = this.getTotalAmountOfProducts();

    if (!this.hasECommerce) {
      this.formControls.url.setErrors(null);
    }

    const productSelection: ProductSelectionModel = new ProductSelectionModel();

    productSelection.products = [];

    for (let index = 0; index < this.technologyFormsArray.length; index++) {
      const model: ProductModel = (this.technologyFormsArray.controls[index] as FormGroup).getRawValue();
      if (model.technology && model.price && model.amount) {
        productSelection.offerType = this.proposal.offerDataType.offerType;
        productSelection.products.push(model);
      }
    }

    const hasOnlyPOS =
      this.technologyList.filter(
        (x) => x.technologyGroup === TechnologyGroupEnum.POS && productSelection.products.map((ps) => ps.technology).includes(x.idService)
      ).length === productSelection.products.length;

    if (!hasOnlyPOS && totalAmount > this.MAXIMUM_PRODUCT_AMOUNT) {
      this.dialogService.openDialog(Messages.MAX_PRODUCT_LIMIT_REACHED);
      return Promise.resolve(false);
    }

    if (!productSelection || !productSelection.products || !productSelection.products.length) {
      return Promise.resolve(false);
    }

    productSelection.products.forEach((product) => {
      const productSearch = this.technologyList.find((x) => x.idService === product.technology);
      product.technologyName = productSearch.description;
      product.technologyGroup = productSearch.technologyGroup;
    });

    const hasDuplicates = productSelection.products.filter(
      (item, index, arr) => arr.map((x) => x.technology).indexOf(item.technology) !== index
    ).length;

    if (hasDuplicates) {
      this.dialogService.openDialog(Messages.DUPLICATED_PRODUCTS);
      return Promise.resolve(false);
    }

    const hasECommerce =
      this.technologyList.filter(
        (x) =>
          x.technologyGroup === TechnologyGroupEnum.E_COMMERCE && productSelection.products.map((ps) => ps.technology).includes(x.idService)
      ).length > 0;

    const hasPOS =
      this.technologyList.filter(
        (x) => x.technologyGroup === TechnologyGroupEnum.POS && productSelection.products.map((ps) => ps.technology).includes(x.idService)
      ).length > 0;

    const hasTef =
      this.technologyList.filter(
        (x) =>
          x.idService !== '353' &&
          x.idService !== '354' &&
          x.technologyGroup === TechnologyGroupEnum.TEF &&
          productSelection.products.map((ps) => ps.technology).includes(x.idService)
      ).length > 0;
    this.hasTef = hasTef;

    const hasLNK =
      this.technologyList.filter(
        (technology) =>
          technology.technologyGroup === TechnologyGroupEnum.LNK &&
          productSelection.products.map((ps) => ps.technology).includes(technology.idService)
      ).length > 0;

    this.hasTefBrowser = checkHasTefBrowser(productSelection.products);
    if (this.hasTefBrowser) this.hasTefBrowserOnly = isTefBrowserOnly(productSelection.products);

    const hasTFIOrTFX =
      this.technologyList.filter(
        (technology) =>
          (technology.idService === '330' || technology.idService === '331') &&
          productSelection.products.map((ps) => ps.technology).includes(technology.idService)
      ).length > 0;

    // TODO: Retirar condição quando for permitido um CP e um CNP
    if (hasECommerce && (hasPOS || hasTef)) {
      this.dialogService.openDialog(Messages.COM_AND_OTHERS_IN_THE_SAME_PROPOSAL);
      return Promise.resolve(false);
    }

    if (this.hasSplitTech() && totalAmount > 1) {
      this.dialogService.openDialog(Messages.SPLITS_TECH_MUST_BE_UNIQUE);
      return Promise.resolve(false);
    }

    if ((hasECommerce || hasLNK) && this.hasECommerceExceptionCnaeSelected) {
      this.dialogService.openDialog(Messages.CNAE_NOT_ELEGIBLE_CNP);
      return Promise.resolve(false);
    }

    if (this.hasECommerce) {
      this.dataStore.clearProposalKey('workingHours');
    }

    if (this.hasECommerceExceptionCnaeSelected && this.hasECommerce) {
      const commerceFunctionalities = new ECommerceFunctionalitiesModel();

      commerceFunctionalities.linkPayment = this.paymentLinkItem.selected;
      commerceFunctionalities.link = this.formControls.url.value;
      this.dataStore.updateProposal({ commerceFunctionalities });
    } else {
      this.dataStore.clearProposalKey('commerceFunctionalities');
    }

    const isISONovo = this.checkISONovo();

    productSelection.hasECommerce = hasECommerce;
    productSelection.hasTef = hasTef;
    productSelection.hasTefBrowser = this.hasTefBrowser;
    productSelection.hasEdi = this.validateEdi();
    productSelection.hasSplitTech = this.hasSplitTech();
    productSelection.hasEcMaster = this.paymentSplitItem.selected;

    this.checkJumpAllEcommerceStatus(hasECommerce && !this.proposal.offerDataDetails.eCommerceExceptionCnaeSelected);
    this.checkJumpDeliverySteps(hasECommerce);
    this.checkJumpAllTefStatus(hasTef, this.hasTefBrowserOnly, isISONovo, hasTFIOrTFX);
    this.checkJumpAllTefBrowserStatus(this.hasTefBrowser);
    this.checkJumpAllEdiStatus(this.ediItem.selected);

    if (this.hasSplitTech()) {
      const paymentSplit: PaymentSplit = new PaymentSplit();
      paymentSplit.ecMasterDocument = this.formControls.ecMasterDocument.value;
      paymentSplit.ecType = 'EC_SECUNDARIO';
      return this.dataStore.updateProposal({ paymentSplit, productSelection });
    }
    return this.dataStore.updateProposal({ productSelection });
  }

  protected updateFields(proposal: Proposal) {
    const offerDataType = proposal.offerDataType;

    if (!offerDataType || !offerDataType.offerType) {
      this.dialogService.openErrorDialog(
        Messages.FAILED_TO_GET_PROPOSAL_DATA,
        'Api Fiserv Online - Tecnologia',
        () => this.back(),
        null,
        'error'
      );
      return;
    }

    if (proposal.commerceFunctionalities) {
      this.formControls.url.setValue(proposal.commerceFunctionalities.link);
      this.paymentLinkItem.selected = !!proposal.commerceFunctionalities.linkPayment;
    }

    if (proposal.productSelection) {
      this.ediItem.selected = proposal.productSelection.hasEdi;
      if (proposal.productSelection.products && proposal.productSelection.products.length > 0) {
        if (offerDataType.offerType !== proposal.productSelection.offerType) {
          proposal.productSelection.products = [];
          this.addTechnologyForm();
        } else {
          const eCommerceFilter =
            this.proposal.registrationDataPersonalInfo && this.proposal.registrationDataPersonalInfo.onlyECommerce
              ? this.ECOMMERCE_TECHNOLOGY_GROUP
              : null;

          let personType = PersonTypeEnum.LEGAL;
          if (this.isPf()) {
            personType = PersonTypeEnum.PHYSICAL;
          }

          if (!this.technologyListUpdatePrice) {
            this.technologyService
              .getServiceList(
                personType,
                Number(this.proposal.offerDataDetails.revenueRange),
                this.serviceContract,
                this.proposal.offerDataDetails.campaign,
                this.proposal.offerDataType.offerType,
                false,
                eCommerceFilter,
                this.proposal.offerDataDetails.cnae
              )
              .subscribe((data) => {
                this.technologyListUpdatePrice = data;

                proposal.productSelection.products.forEach((product, index) => {
                  this.getAlltechnology(product, index);
                });
              });
          }
        }
      } else {
        this.addTechnologyForm();
      }
    } else {
      this.addTechnologyForm();
    }

    this.paymentSplitItem.selected = proposal && proposal.productSelection && proposal.productSelection.hasEcMaster;

    if (this.proposal && this.proposal.productSelection && this.proposal.productSelection.hasSplitTech) {
      this.formControls.ecMasterDocument.setValidators([Validators.required, CpfCnpjValidator]);
      if (this.proposal.paymentSplit && this.proposal.paymentSplit.ecMasterDocument) {
        this.formControls.ecMasterDocument.setValue(this.proposal.paymentSplit.ecMasterDocument);
      } else {
        this.formControls.ecMasterDocument.setValue('');
      }
      this.dialogService.openDialog(Messages.WARN_ABOUT_SPLIT_TECHNOLOGY);
    } else {
      this.formControls.ecMasterDocument.clearValidators();
      this.formControls.ecMasterDocument.updateValueAndValidity();
    }
    this.checkAbleSplit();
  }

  protected createFormGroup() {
    return this.formBuilder.group({
      ecMasterDocument: [''],
      url: [
        '',
        [WebsiteValidator, RequiredIfValidator(() => this.hasECommerce && this.proposal.offerDataDetails.eCommerceExceptionCnaeSelected)],
      ],
      technologyForms: this.formBuilder.array([]),
    });
  }

  protected createFields(): FdFieldConfigs {
    return {
      technology: {
        items: [],
        controlName: 'technology',
        label: 'Selecione a tecnologia',
        searchPlaceholder: 'Insira o nome da tecnologia',
        messages: {
          required: 'Selecione uma tecnologia',
          invalid: 'tecnologia inválida',
        },
      },
      amount: {
        disabled: this.isSales,
        controlName: 'amount',
        label: 'Quantidade',
        messages: {
          required: 'Informe a quantidade',
          invalid: 'quantidade inválida',
        },
      },
      price: {
        controlName: 'price',
        maskCharsReplace: /[R$ ]/,
        label: 'Valor unitário',
        ...this.getSpecificPriceProperties(),
      },
      url: {
        label: 'URL',
        controlName: 'url',
        messages: {
          invalid: 'URL inválida',
          required: 'Informe a URL',
        },
      },
      ecMasterDocument: {
        label: 'CNPJ do EC Master correspondente',
        controlName: 'ecMasterDocument',
        mask: cpfCnpjMask,
        isNumber: true,
        maskCharsReplace: /[.\/ -]/g,
        messages: {
          required: 'Informe o CNPJ do EC Master correspondente',
          invalidCnpj: 'CNPJ inválido',
          invalidCpf: 'CPF inválido',
        },
      },
    };
  }

  private checkSplitTechs() {
    const func = () => this.back();
    this.proposalService
      .getSplitTechs()
      .toPromise()
      .then((response) => {
        this.splitTechs = response;
      })
      .catch((e) => {
        this.splitTechs = null;
        console.error(e);
        this.dialogService.openErrorDialog(Messages.ERROR_GET_CHECK_SPLITS_TECH, e.error.field, func, null, 'error');
      });
  }

  private validateEdi(): boolean {
    if (this.hasSplitTech()) {
      return false;
    }
    return !this.isSales ? this.ediItem.selected : false;
  }

  private renderPlusIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'plus-circle' });
    return iconDefinition.html;
  }

  private renderCloseIcon(): SafeHtml {
    const iconDefinition = icon({ prefix: 'fas', iconName: 'times' });
    return iconDefinition.html;
  }

  private validatePOS(): void {
    if (this.hasPOS) {
      const posList = this.technologyList.filter((x) => x.technologyGroup === TechnologyGroupEnum.POS).map((x) => x.idService);

      if (posList && posList.length) {
        const posAmountArr = this.selectedProducts.filter((x) => posList.includes(x.technology)).map((y) => y.amount);

        if (!posAmountArr || !posAmountArr.length) {
          this.next();
        }

        const posAmount = posAmountArr.reduce((prev, curr) => prev + curr);

        this.terminalService.checkPOSAmount(this.serviceContract, this.proposal.registrationDataPersonalInfo.cpfCnpj, posAmount).subscribe(
          (response) => {
            if (!response) {
              this.dialogService.openDialog(Messages.ERROR_WHEN_VALIDATING_POS_AMOUNT);
            } else if (!response.valido) {
              this.dialogService.openDialog(Messages.UNABLE_TO_PROCEED_POS_AMOUNT);
            } else if (posAmount > response.numberOfTerminalsAvailable) {
              this.dialogService.openDialogWithMessage(
                `Só é possível contratar ${response.numberOfTerminalsAvailable} tecnologias POS para este cliente`
              );
            } else {
              this.next();
            }
          },
          (err: HttpErrorResponse) => {
            this.dialogService.openDialog(Messages.ERROR_WHEN_VALIDATING_POS_AMOUNT);
          }
        );
      }
    } else {
      this.next();
    }
  }

  private checkIfISO(technologyGroup: string) {
    if (!technologyGroup) {
      return '';
    }
    if (technologyGroup.trim().toUpperCase() === 'ISO') {
      return 'POS';
    }
    return technologyGroup;
  }

  private validateIfSipagAndPos(): void {
    const selectedProduct: ProductModel = this.selectedProducts && this.selectedProducts.length ? this.selectedProducts[0] : null;
    if (selectedProduct) {
      const technologySelected: TechnologyModel = this.technologyList.find((x) => x.idService === selectedProduct.technology);
      if (technologySelected && this.dataStoreService.isBinServiceContractProposalTecnology(this.proposal.serviceContract)) {
        this.clientService
          .validateTechnologyGroup(
            this.proposal.registrationDataPersonalInfo.cpfCnpj,
            this.proposal.institution,
            this.checkIfISO(technologySelected.technologyGroup)
          )

          .subscribe(
            (response) => {
              if (!response) {
                this.dialogService.openErrorDialog(Messages.TYPE_SERVICE_INVALID_SIPAG, 'Api Cliente Existente - Tecnologia');
              } else {
                this.validatePOS();
              }
            },
            (error) => {
              this.dialogService.openDialog(Messages.GENERAL_ERROR);
              console.error('[Client Technology Validation] - Unspecified error when querying CPF/CNPJ. ', error);
            }
          );
      } else {
        this.validatePOS();
      }
    }
  }

  private createTechnologyForm(product: Partial<ProductModel>): FormGroup {
    return this.formBuilder.group({
      technologyName: [''],
      technology: [product.technology, Validators.required],
      amount: [product.amount, Validators.required],
      price: [product.price, Validators.required],
      priceEdit: [''],
      technologyGroup: [''],
    });
  }

  private mapToItemArray(items: TechnologyModel[]): Item[] {
    return (
      items &&
      items.map((value) => ({
        label: this.tecnologyLabelName(value),
        value: value.idService,
      }))
    );
  }

  private tecnologyLabelName(item: TechnologyModel): string {
    return item.tradeName
      ? item.tradeName.concat(' (', item.technologyGroup, ')')
      : item.description.concat(' (', item.technologyGroup, ')');
  }

  private mapToItemArrayWithRestrictedTechnology(items: TechnologyModel[]): Item[] {
    items.forEach((tech, index, arr) => {
      const isTfi = arr.some(
        (x) => x.technologyGroup === TechnologyGroupEnum.TEF && tech.idService === TechnologySolutionEnum.TFI_CONEXAO_IP
      );

      if (isTfi) {
        items.splice(index, 1);
      }
    });
    return (
      items &&
      items.map((value) => ({
        label: value.tradeName
          ? value.tradeName.concat(' (', value.technologyGroup, ')')
          : value.description.concat(' (', value.technologyGroup, ')'),
        value: value.idService,
      }))
    );
  }

  private getServiceList(index: number, offerType: OfferTypeEnum, hideISO: boolean) {
    let personType = PersonTypeEnum.LEGAL;
    if (this.isPf()) {
      personType = PersonTypeEnum.PHYSICAL;
    }

    const eCommerceFilter =
      this.proposal.registrationDataPersonalInfo && this.proposal.registrationDataPersonalInfo.onlyECommerce
        ? this.ECOMMERCE_TECHNOLOGY_GROUP
        : null;
    this.technologyService
      .getServiceList(
        personType,
        Number(this.proposal.offerDataDetails.revenueRange),
        this.serviceContract,
        this.proposal.offerDataDetails.campaign,
        offerType,
        hideISO,
        eCommerceFilter,
        this.proposal.offerDataDetails.cnae
      )
      .pipe(
        tap((serviceList) =>
          this.dataStore.appendDropdownItems(this.fields.technology.controlName, this.loadTechnologiesList(serviceList, index))
        )
      )
      .pipe(takeWhile(() => this.alive))
      .subscribe(
        (services) => {
          if (services === null) {
            this.dialogService.openDialog(Messages.NO_TECHNOLOGIES_FOUND, () => this.wizardService.previousStep());
          }
          this.technologyList = services;

          this.checkIfHasTef();
          this.checkPOSAmount();
          this.checkEcommerceAmount();
        },
        (error) => {
          this.dialogService.openDialog(Messages.FAILED_TO_GET_TECHNOLOGIES, () => this.wizardService.previousStep());
        }
      );
  }

  public checkJumpAllEdiStatus(hasEdi: boolean) {
    if (!hasEdi) {
      this.dataStore.deleteEdiProperties();
    }

    this.setStepJump(
      orderRoutingDefinitions.ediComplementaryInformation.stepNumber,
      orderRoutingDefinitions.ediComplementaryInformation.routes.EDI_ADDITIONAL_DATA.order,
      !hasEdi
    );
  }

  public checkJumpClover() {}

  public checkJumpAllTefStatus(hasTef: boolean, hasTefBrowserOnly: boolean, isISONovo: boolean, hasTFIOrTFX: boolean) {
    let jump: boolean = false;

    if (!hasTef || hasTefBrowserOnly || (isISONovo && hasTFIOrTFX)) {
      jump = true;
      this.dataStore.deleteTefProperties();
    }

    this.setStepJump(
      orderRoutingDefinitions.tefComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefComplementaryInformation.routes.TEF_IT_CONTACT.order,
      jump
    );
    this.setStepJump(
      orderRoutingDefinitions.tefComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefComplementaryInformation.routes.TEF_PIN_PAD_INFORMATION.order,
      jump
    );
    this.setStepJump(
      orderRoutingDefinitions.tefComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefComplementaryInformation.routes.TEF_BUSINESS_INFORMATION.order,
      jump
    );
    this.setStepJump(
      orderRoutingDefinitions.tefComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefComplementaryInformation.routes.FIRST_DATA_PIN_PAD.order,
      jump
    );
    this.setStepJump(
      orderRoutingDefinitions.tefComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefComplementaryInformation.routes.TEF_PROVIDER_INFORMATION.order,
      jump
    );
    this.setStepJump(
      orderRoutingDefinitions.tefComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefComplementaryInformation.routes.TEF_INTEGRATION.order,
      jump
    );
    this.setStepJump(
      orderRoutingDefinitions.tefComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefComplementaryInformation.routes.TEF_ACTIVATION_PERIOD.order,
      jump
    );
  }

  public checkJumpAllTefBrowserStatus(hasTefBrowser: boolean) {
    if (!hasTefBrowser) {
      this.dataStore.deleteTefBrowserProperties();
    }

    this.setStepJump(
      orderRoutingDefinitions.tefBrowserComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefBrowserComplementaryInformation.routes.TEF_BROWSER_IT_CONTACT.order,
      !hasTefBrowser
    );
    this.setStepJump(
      orderRoutingDefinitions.tefBrowserComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefBrowserComplementaryInformation.routes.TEF_BROWSER_INTEGRATION.order,
      !hasTefBrowser
    );
    this.setStepJump(
      orderRoutingDefinitions.tefBrowserComplementaryInformation.stepNumber,
      orderRoutingDefinitions.tefBrowserComplementaryInformation.routes.TEF_BROWSER_ACTIVATION_PERIOD.order,
      !hasTefBrowser
    );
  }

  public checkJumpAllEcommerceStatus(hasECommerce: boolean) {
    if (!hasECommerce) {
      this.dataStore.deleteECommerceProperties();
    }
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.E_COMMERCE_FUNCTIONALITIES.order,
      !hasECommerce
    );
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.GATEWAY_ANTIFRAUD_INFORMATION.order,
      !hasECommerce
    );
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.BRANCH_OFFICES.order,
      !hasECommerce
    );
    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.BUSINESS_PROSPECTION.order,
      !hasECommerce
    );

    this.setStepJump(
      orderRoutingDefinitions.eCommerceComplementaryInformation.stepNumber,
      orderRoutingDefinitions.eCommerceComplementaryInformation.routes.ENTERPRISE_ADDITIONAL_QUESTIONS.order,
      !hasECommerce
    );
  }

  public cloverToolTipText() {
    return 'O extrato EDI será sempre habilitado para tecnologia clover, pois ele é essencial para o GFIN';
  }

  public showTooltipAndStopPropagation() {
    this.tooltip.toggle();
    event.stopImmediatePropagation();
  }

  checkISONovo() {
    const userData = this.dataStoreService.getUserData();
    return (
      userData.institution === this.INSTITUTION_FISERV &&
      userData.serviceContract === this.SERVICE_CONTRACT_BIN &&
      userData.channelType === this.ID_CHANNEL_TYPE_ISO_NOVO
    );
  }
}

function checkHasTefBrowser(products: ProductModel[]): boolean {
  return products.some((x) => x.technology === TechnologySolutionEnum.PTF_TEF_BROWSER);
}

function isTefBrowserOnly(products: ProductModel[]): boolean {
  return (
    products.filter((x) => x.technologyGroup === TechnologyGroupEnum.TEF && x.technology !== TechnologySolutionEnum.PTF_TEF_BROWSER)
      .length === 0
  );
}
