import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { MatDialog } from '@angular/material/dialog';
import { CompareTypeEnum } from 'src/app/order/enums/compare-type.enum';
import { ProposalService } from 'src/app/order/services/external/proposal/proposal.service';
import { DialogService } from 'src/app/order/services/internal/dialog/dialog.service';
import { LoadingService } from 'src/app/order/services/internal/loading/loading.service';
import { ScrollService } from 'src/app/order/services/internal/scroll/scroll.service';
import { WizardService } from 'src/app/order/services/internal/wizard/wizard.service';
import { DataStore } from 'src/app/order/store/data.store';
import { businessDayOptions } from 'src/app/order/utils/business-day-options';
import { businessDayOptionsDefault } from 'src/app/order/utils/business-day-options-default';
import { hourOptions } from 'src/app/order/utils/hour-options';
import { CompareValidator } from 'src/app/order/validators/compare-validator';
import { RequiredIfValidator } from 'src/app/order/validators/required-if-validator';
import { FdRadioButtonConfig } from 'src/app/shared/fd-form-components/fd-radio-button/fd-radio-button.component';
import { FdSelectConfig, Item } from 'src/app/shared/fd-form-components/fd-select/fd-select.component';
import { Proposal, WorkingHoursModel } from 'src/app/shared/models/proposal';
import { FdFieldConfigs } from 'src/app/shared/shared-components.module';
import { ConfigurationService } from 'src/app/start/services/configuration.service';
import { FormStep } from '../../form-step';
import { environment } from 'src/environments/environment.local';
import { orderRoutingDefinitions } from 'src/app/order/routing/routing-definitions';

import { RoutingService } from 'src/app/order/services/internal/routing/routing.service';
import { DataStoreService } from 'src/app/order/store/data-store.service';
import { ModalService } from 'src/app/order/services/external/modal/modal.service';
import { take } from 'rxjs/operators';
import { Modal } from 'src/app/shared/models/response/modal-response';
import { ConfigurationModel } from 'src/app/start/models/configuration/configuration.model';
import { ConciliatorService } from 'src/app/order/services/external/conciliator/conciliator.service';
import { ConciliatorEnum, EdiAdditionalDataModel } from '../../complementary-information/edi/models/edi-additional-data.model';
import { Messages } from 'src/app/order/messages/order.messages';

@Component({
  selector: 'app-working-hours',
  templateUrl: './working-hours.component.html',
  styleUrls: ['./working-hours.component.scss'],
})
export class WorkingHoursComponent extends FormStep implements OnInit {
  get closesForLunch() {
    return this.formControls.lunchClosing.value;
  }
  public workingHoursModel: WorkingHoursModel;
  public configuration: ConfigurationModel;
  public hasCloverTech: boolean = false;
  public ediAdditionalData: EdiAdditionalDataModel;

  protected STEP_NUMBER = 0;

  constructor(
    private formBuilder: FormBuilder,
    protected proposalService: ProposalService,
    protected loadingService: LoadingService,
    protected dialogService: DialogService,
    protected dataStore: DataStore,
    protected scrollService: ScrollService,
    protected wizardService: WizardService,
    protected matDialog: MatDialog,
    protected configurationService: ConfigurationService,
    protected routingService: RoutingService,
    protected dataStoreService: DataStoreService,
    private modalService: ModalService,
    protected conciliatorService: ConciliatorService
  ) {
    super(
      dataStore,
      scrollService,
      wizardService,
      matDialog,
      proposalService,
      loadingService,
      dialogService,
      configurationService,
      routingService,
      dataStoreService
    );
  }

  public async ngOnInit() {
    await super.ngOnInit();
    setTimeout(() => this.appendValidators());
    this.configuration = this.configurationService.getConfigurationFromSessionStorage();
    this.findModal();
    this.checkEdiStepJump();
  }

  public loadHours(filterValue: string, controlName: string) {
    const filteredItems = hourOptions.filter((item) => !filterValue || item.value.indexOf(filterValue) > -1);

    (this.fields[controlName] as FdSelectConfig).items = [];
    (this.fields[controlName] as FdSelectConfig).items = filteredItems;
  }

  public clearFields(event: boolean) {
    if (event) {
      this.formControls.lunchClosingHour.setValue('');
      this.formControls.lunchReopenHour.setValue('');
    } else {
      this.formControls.lunchClosingHour.setErrors(null);
      this.formControls.lunchReopenHour.setErrors(null);
    }
  }

  public createFields(): FdFieldConfigs {
    const hourFieldDefinitions = {
      items: hourOptions,
      searchable: true,
      searchPlaceholder: 'Digite o horário',
      messages: {
        required: 'Informe um horário',
        lessThanOrEqual: 'O horário de abertura não pode ser maior ou igual ao de fechamento',
        greaterThanOrEqual: 'O horário de fechamento não pode ser menor ou igual ao de abertura',
      },
    };

    return {
      businessDays: {
        label: 'Dias',
        controlName: 'businessDays',
        items: businessDayOptions,
        modalTitle: 'Selecione um ou mais dias de funcionamento:',
        placeholder: 'Selecione os dias',
        messages: {
          required: 'Informe um dia de funcionamento',
        },
      },
      weekOpeningHour: {
        label: 'Abre às',
        controlName: 'weekOpeningHour',
        ...hourFieldDefinitions,
      },
      weekClosingHour: {
        label: 'Fecha às',
        controlName: 'weekClosingHour',
        ...hourFieldDefinitions,
      },
      weekendOpeningHour: {
        label: 'Abre às',
        controlName: 'weekendOpeningHour',
        ...hourFieldDefinitions,
      },
      weekendClosingHour: {
        label: 'Fecha às',
        controlName: 'weekendClosingHour',
        ...hourFieldDefinitions,
      },
      lunchClosing: {
        controlName: 'lunchClosing',
        items: [],
        messages: {
          required: 'Informe se o estabelecimento fecha para almoço',
        },
      },
      lunchClosingHour: {
        label: 'Fecha às',
        controlName: 'lunchClosingHour',
        items: hourOptions,
        searchable: true,
        searchPlaceholder: 'Digite o horário',
        messages: {
          required: 'Informe um horário',
          lessThanOrEqual: 'O horário de fechamento não pode ser maior ou igual ao de reabertura',
          greaterThanOrEqual: 'O horário de fechamento não pode ser maior ou igual ao de reabertura',
        },
      },
      lunchReopenHour: {
        label: 'Reabre às',
        controlName: 'lunchReopenHour',
        items: hourOptions,
        searchable: true,
        searchPlaceholder: 'Digite o horário',
        messages: {
          required: 'Informe um horário',
          lessThanOrEqual: 'O horário de reabertura não pode ser menor ou igual ao de fechamento',
          greaterThanOrEqual: 'O horário de reabertura não pode ser menor ou igual ao de fechamento',
        },
      },
    };
  }

  public async checkEdiStepJump() {
    const products = this.proposal.productSelection.products;

    this.hasCloverTech = products.some((tech) => tech.technology === '353' || tech.technology === '354');
    if (this.hasCloverTech) {
      this.setStepJump(
        orderRoutingDefinitions.ediComplementaryInformation.stepNumber,
        orderRoutingDefinitions.ediComplementaryInformation.routes.EDI_ADDITIONAL_DATA.order,
        this.hasCloverTech
      );

      this.conciliatorService.getEmailsConciliatorById(ConciliatorEnum.CONCILIATOR_SE).subscribe(
        (item) => {
          this.ediAdditionalData = item;
          this.ediAdditionalData.idConciliatorCompany = 1;
        },
        () => this.dialogService.openDialog(Messages.GENERAL_ERROR)
      );
    }
  }

  public async persistData() {
    const workingHours: WorkingHoursModel = {
      ...this.formGroup.getRawValue(),
    };

    if (this.hasCloverTech) {
      let ediAdditionalData = this.ediAdditionalData;

      await this.dataStore.updateProposal({ ediAdditionalData });
    }

    const zeroHours = '00:00';
    workingHours.weekendOpeningHour = workingHours.weekendOpeningHour ? workingHours.weekendOpeningHour : zeroHours;
    workingHours.weekendClosingHour = workingHours.weekendClosingHour ? workingHours.weekendClosingHour : zeroHours;

    return this.dataStore.updateProposal({ workingHours });
  }

  public updateValueAndValidity() {
    this.formControls.weekOpeningHour.updateValueAndValidity();
    this.formControls.weekClosingHour.updateValueAndValidity();
    this.formControls.weekendOpeningHour.updateValueAndValidity();
    this.formControls.weekendClosingHour.updateValueAndValidity();
    this.formControls.lunchClosingHour.updateValueAndValidity();
    this.formControls.lunchReopenHour.updateValueAndValidity();
    this.formControls.lunchClosing.updateValueAndValidity();
    this.formControls.businessDays.updateValueAndValidity();
  }

  public updateFields(proposal: Proposal) {
    this.workingHoursModel = proposal.workingHours;
    if (this.workingHoursModel) {
      this.formControls.weekOpeningHour.setValue(this.workingHoursModel.weekOpeningHour);
      this.formControls.weekClosingHour.setValue(this.workingHoursModel.weekClosingHour);
      this.formControls.weekendOpeningHour.setValue(this.workingHoursModel.weekendOpeningHour);
      this.formControls.weekendClosingHour.setValue(this.workingHoursModel.weekendClosingHour);
      this.formControls.lunchClosingHour.setValue(this.workingHoursModel.lunchClosingHour);
      this.formControls.lunchReopenHour.setValue(this.workingHoursModel.lunchReopenHour);
      this.formControls.lunchClosing.setValue(this.workingHoursModel.lunchClosing);
      this.formControls.businessDays.setValue(this.workingHoursModel.businessDays);
      (this.fields.weekOpeningHour as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === this.workingHoursModel.weekOpeningHour)
      );
      (this.fields.weekClosingHour as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === this.workingHoursModel.weekClosingHour)
      );
      (this.fields.weekendOpeningHour as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === this.workingHoursModel.weekendOpeningHour)
      );
      (this.fields.weekendClosingHour as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === this.workingHoursModel.weekendClosingHour)
      );
      (this.fields.lunchClosingHour as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === this.workingHoursModel.lunchClosingHour)
      );
      (this.fields.lunchReopenHour as FdSelectConfig).items.map(
        (item) => (item.selected = item.value === this.workingHoursModel.lunchReopenHour)
      );
      (this.fields.lunchClosing as FdRadioButtonConfig).items.map(
        (item) => (item.selected = item.value === this.workingHoursModel.lunchClosing)
      );
    }
  }

  public appendValidators() {
    this.formControls.businessDays.setValidators([Validators.required]);
    this.formControls.weekOpeningHour.setValidators([
      Validators.required,
      CompareValidator(this.formControls.weekClosingHour, CompareTypeEnum.LESS_THAN_OR_EQUAL),
    ]);
    this.formControls.weekClosingHour.setValidators([
      Validators.required,
      CompareValidator(this.formControls.weekOpeningHour, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
    ]);
    this.formControls.weekendOpeningHour.setValidators([
      RequiredIfValidator(() => this.formControls.weekendClosingHour.value && this.formControls.weekendClosingHour.value !== ''),
      CompareValidator(this.formControls.weekendClosingHour, CompareTypeEnum.LESS_THAN_OR_EQUAL),
    ]);
    this.formControls.weekendClosingHour.setValidators([
      RequiredIfValidator(() => this.formControls.weekendOpeningHour.value && this.formControls.weekendOpeningHour.value !== ''),
      CompareValidator(this.formControls.weekendOpeningHour, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
    ]);
    this.formControls.lunchClosing.setValidators([Validators.required]);
    this.formControls.lunchClosingHour.setValidators([
      RequiredIfValidator(() => this.closesForLunch),
      CompareValidator(this.formControls.lunchReopenHour, CompareTypeEnum.LESS_THAN_OR_EQUAL),
    ]);
    this.formControls.lunchReopenHour.setValidators([
      RequiredIfValidator(() => this.closesForLunch),
      CompareValidator(this.formControls.lunchClosingHour, CompareTypeEnum.GREATER_THAN_OR_EQUAL),
    ]);
  }

  public next() {
    this.updateValueAndValidity();
    super.next();
  }

  public createFormGroup(): FormGroup {
    return this.formBuilder.group({
      businessDays: [''],
      weekOpeningHour: [''],
      weekClosingHour: [''],
      weekendOpeningHour: [''],
      weekendClosingHour: [''],
      lunchClosing: [''],
      lunchClosingHour: [''],
      lunchReopenHour: [''],
    });
  }
  protected proposalGetCallback(): void {}

  public showButtonStandardBusinessHours(): boolean {
    return environment && environment.development;
  }

  public setStandardBusinessHours(): void {
    const businessDays = businessDayOptionsDefault;

    this.formControls.weekOpeningHour.setValue('08:00');
    this.formControls.weekClosingHour.setValue('18:00');
    this.formControls.weekendOpeningHour.setValue('08:00');
    this.formControls.weekendClosingHour.setValue('13:00');
    this.formControls.lunchClosingHour.setValue('');
    this.formControls.lunchReopenHour.setValue('');
    this.formControls.lunchClosing.setValue(false);
    this.formControls.businessDays.setValue(businessDays);
    (this.fields.weekOpeningHour as FdSelectConfig).items.map((item) => (item.selected = item.value === '08:00'));
    (this.fields.weekClosingHour as FdSelectConfig).items.map((item) => (item.selected = item.value === '18:00'));
    (this.fields.weekendOpeningHour as FdSelectConfig).items.map((item) => (item.selected = item.value === '08:00'));
    (this.fields.weekendClosingHour as FdSelectConfig).items.map((item) => (item.selected = item.value === '13:00'));
    (this.fields.lunchClosingHour as FdSelectConfig).items.map((item) => (item.selected = item.value === ''));
    (this.fields.lunchReopenHour as FdSelectConfig).items.map((item) => (item.selected = item.value === ''));
    (this.fields.lunchClosing as FdRadioButtonConfig).items.map((item) => (item.selected = item.value === false));
  }

  public findModal() {
    this.modalService
      .getModal(this.proposal.deliveryAddress.zipCode)
      .pipe(take(1))
      .subscribe(
        (response) => {
          this.checkJumpDeliveryHoursWhenEmptyModalAndDeliveryMachineIsDisable(this.configuration.machineAgency, response);
        },
        (error) => {
          this.checkJumpDeliveryHoursWhenEmptyModalAndDeliveryMachineIsDisable(this.configuration.machineAgency, null);
        }
      );
  }

  public checkJumpDeliveryHoursWhenEmptyModalAndDeliveryMachineIsDisable(machineAgency: boolean, modal: Modal) {
    let jump: boolean = true;
    if (modal) {
      if (machineAgency && modal.serviceProviders.length > 0) {
        jump = false;
      }
    }

    this.setStepJump(
      orderRoutingDefinitions.openingHours.stepNumber,
      orderRoutingDefinitions.openingHours.routes.DELIVERY_HOURS.order,
      jump
    );
  }
}
