import { Component, Input, OnInit } from '@angular/core';
import { IdentifyInfo } from '../../models/child-models/identify-info-model';

@Component({
  selector: 'app-identify-data-detail',
  templateUrl: './identify-data-detail.component.html',
  styleUrls: ['./identify-data-detail.component.scss'],
})
export class IdentifyDataDetailComponent {
  @Input()
  public identifyInfo: IdentifyInfo;

  constructor() {}
}
